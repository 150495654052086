<template>
  <div class="sql_query">
    <section-title title="SQL查询库" class="margin-left-25"></section-title>
    <div class="sql_query_panel">
      <el-form size="small" :inline="true" class="margin-left-20 margin-right-20" @submit.native.prevent="onSearch">
        <el-form-item>
          <el-input v-model="queryData.keyword" placeholder="按名称搜索" :clearable="true" @clear="onInputClear">
            <i slot="prefix" class="el-input__icon iconfont iconfangdajing" @click="onSearch"></i>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="panel_body">
        <vue-scroll>
          <div class="panel_body_wrapper">
            <el-table :data="sqlList" class="my-el-table" v-loading="loading" element-loading-background="rgba(24,34,45,0.9)" cell-class-name="my-el-table-cell" @sort-change="onSortChange">
              <el-table-column label="名称" prop="sqlName" sortable="custom"></el-table-column>
              <el-table-column label="数据源" prop="dataSourceName" sortable="custom"></el-table-column>
              <el-table-column label="描述" prop="description"></el-table-column>
              <el-table-column label="最近一次修改人" prop="changedByUser" sortable="custom"></el-table-column>
              <el-table-column label="最近一次修改时间" prop="updatedAt" sortable="custom"></el-table-column>
              <el-table-column>
                <template slot-scope="scope">
                  <div class="table_icons">
                    <i class="iconfont icontupian" title="制作图表" @click="onChart(scope.row)"></i>
                    <i class="iconfont iconbianji" title="编辑查询" @click="onEdit(scope.row)"></i>
                    <i class="iconfont iconshanchu" title="删除" @click="onDelete(scope.row)"></i>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </vue-scroll>
      </div>
      <div class="panel_footer">
        <el-pagination
          class="my-el-pagination"
          :background="true"
          layout="prev, pager, next"
          :page-count="pageCount"
          @current-change="page => getSqlList(page)">
        </el-pagination>
      </div>
    </div>

    <el-dialog
      title="编辑保存的查询"
      :visible.sync="showEditDialog"
      width="600px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onClose">
      <el-form ref="form" :model="editItem" :rules="rules" size="small" hide-required-asterisk
        label-position="left" label-width="160px" class="margin-left-20 margin-right-20">
        <el-form-item label="名称" prop="sqlName">
          <el-input v-model="editItem.sqlName"></el-input>
        </el-form-item>
        <el-form-item label="数据源">
          <el-input v-model="editItem.dataSourceName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="editItem.description"></el-input>
        </el-form-item>
        <el-form-item label="SQL">
          <el-input type="textarea" :rows="9" :disabled="true" v-model="editItem.sql"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="text" @click="onEditDetail(editItem.sqlId)">修改SQL模型</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="showEditDialog = false">取消</el-button>
        <el-button type="primary" size="small" @click="editConfirmed">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as api from '~/api'
export default {
  data () {
    return {
      loading: true,
      sqlList: [],
      showEditDialog: false,
      queryData: {
        keyword: '',
        orderWord: '',
        orderType: '',
        pageNum: 1,
        pageSize: 15
      }, // 搜索，排序，分页相关设置
      pageCount: 0, // 总页数
      editItem: {
        sqlId: '',
        sqlName: '',
        sql: '',
        dataSourceId: '',
        dataSourceName: '',
        description: '',
        changedByUser: '',
        updatedAt: ''
      }, // 当前编辑的查询
      rules: {
        sqlName: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    projectId () {
      return this.$route.query.projectId || ''
    },
    projectName () {
      return this.$route.query.projectName || ''
    }
  },
  mounted () {
    this.queryData.keyword = ''
    this.queryData.orderWord = ''
    this.queryData.orderType = ''
    this.getSqlList(1)
  },
  methods: {
    // 获取sql查询列表
    getSqlList (page) {
      this.loading = true
      this.queryData.pageNum = page
      api.getSavedQueryList({
        spaceId: this.projectId,
        ...this.queryData
      }).then(res => {
        this.loading = false
        if (res.data.code === 0) {
          this.queryData.pageNum = res.data.data.currentPage
          this.pageCount = res.data.data.lastPage
          this.sqlList = res.data.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        this.loading = false
        console.log(err)
        this.$message.error('网络错误')
      })
    },
    // 输入框搜索
    onSearch () {
      this.queryData.orderWord = ''
      this.queryData.orderType = ''
      this.getSqlList(1)
    },
    onInputClear () {
      this.queryData.orderWord = ''
      this.queryData.orderType = ''
      this.getSqlList(1)
    },
    // 表格排序
    onSortChange ({ column, prop, order }) {
      // console.log(column, prop, order)
      this.queryData.orderWord = prop
      if (order === 'ascending') {
        this.queryData.orderType = 'asc'
      } else if (order === 'descending') {
        this.queryData.orderType = 'desc'
      } else {
        this.queryData.orderType = ''
      }

      this.getSqlList(1)
    },
    // 使用sql建立制作图表
    onChart (item) {
      this.$router.push({
        path: '/chart-detail',
        query: {
          mode: 'create',
          sidebar: 'sql-query',
          projectId: this.projectId,
          projectName: this.projectName,
          dataSourceId: item.dataSourceId,
          dataSourceType: item.dataSourceType,
          sqlId: item.sqlId
        }
      })
    },
    // 点击编辑sql
    onEdit (item) {
      this.showEditDialog = true
      this.editItem = this.$clone(item)
    },
    // dialog关闭时清除表单
    onClose () {
      this.$refs.form.resetFields()
    },
    // 编辑确认
    editConfirmed () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          api.editSavedQueryItem({
            sqlId: this.editItem.sqlId,
            title: this.editItem.sqlName,
            description: this.editItem.description
          }).then(res => {
            if (res.data.code === 0) {
              this.$message.success('sql查询已更新')
              this.showEditDialog = false
              this.getSqlList(this.queryData.pageNum)
            } else {
              this.$message.error(res.data.message)
            }
          }).catch(err => {
            console.log(err)
            this.$message.error('网络错误！')
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 点击删除sql
    onDelete (item) {
      this.$confirm('确定要删除该查询吗？删除后将无法回复', '删除查询', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.deleteSavedQueryItem({
          sqlId: item.sqlId
        }).then(res => {
          if (res.data.code === 0) {
            this.$message.success('该查询已删除')
            this.getSqlList(this.queryData.pageNum)
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          console.log(err)
          this.$message.error('网络错误！')
        })
      }).catch(() => {
        this.$message.info('操作已取消')
      })
    },
    onEditDetail (id) {
      this.$router.push({
        path: '/project-detail/sql-editor',
        query: {
          projectId: this.projectId,
          projectName: this.projectName,
          sqlId: id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.sql_query {
  flex: 1;
  padding: 30px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  &_panel {
    flex: 1;
    margin-top: 20px;
    padding: 20px 0;
    background-color: $black-color-light-1;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }
}
.panel_body {
  flex: 1;
  &_wrapper {
    position: absolute;
    min-width: 100%;
    width: -webkit-fit-content;
    padding: 0 20px;
  }
}
.panel_footer {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table_icons {
  color: $black-color-light-10;
  display: flex;
  align-items: center;
  & > i {
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin: 0 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
